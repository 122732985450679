import React from 'react'

const Loader = (props) => {
  return (
    <div>
      <p>Loading</p>
    </div>
  )
}

export default Loader
